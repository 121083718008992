// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beer-olympics-js": () => import("./../../../src/pages/beer-olympics.js" /* webpackChunkName: "component---src-pages-beer-olympics-js" */),
  "component---src-pages-how-to-index-js": () => import("./../../../src/pages/how-to/index.js" /* webpackChunkName: "component---src-pages-how-to-index-js" */),
  "component---src-pages-how-to-organizers-create-league-js": () => import("./../../../src/pages/how-to/organizers/create-league.js" /* webpackChunkName: "component---src-pages-how-to-organizers-create-league-js" */),
  "component---src-pages-how-to-organizers-faq-js": () => import("./../../../src/pages/how-to/organizers/FAQ.js" /* webpackChunkName: "component---src-pages-how-to-organizers-faq-js" */),
  "component---src-pages-how-to-organizers-handicaps-js": () => import("./../../../src/pages/how-to/organizers/handicaps.js" /* webpackChunkName: "component---src-pages-how-to-organizers-handicaps-js" */),
  "component---src-pages-how-to-organizers-index-js": () => import("./../../../src/pages/how-to/organizers/index.js" /* webpackChunkName: "component---src-pages-how-to-organizers-index-js" */),
  "component---src-pages-how-to-organizers-pricing-js": () => import("./../../../src/pages/how-to/organizers/pricing.js" /* webpackChunkName: "component---src-pages-how-to-organizers-pricing-js" */),
  "component---src-pages-how-to-organizers-schedule-js": () => import("./../../../src/pages/how-to/organizers/schedule.js" /* webpackChunkName: "component---src-pages-how-to-organizers-schedule-js" */),
  "component---src-pages-how-to-organizers-scoring-js": () => import("./../../../src/pages/how-to/organizers/scoring.js" /* webpackChunkName: "component---src-pages-how-to-organizers-scoring-js" */),
  "component---src-pages-how-to-organizers-standings-js": () => import("./../../../src/pages/how-to/organizers/standings.js" /* webpackChunkName: "component---src-pages-how-to-organizers-standings-js" */),
  "component---src-pages-how-to-organizers-teams-players-js": () => import("./../../../src/pages/how-to/organizers/teams-players.js" /* webpackChunkName: "component---src-pages-how-to-organizers-teams-players-js" */),
  "component---src-pages-how-to-players-account-js": () => import("./../../../src/pages/how-to/players/account.js" /* webpackChunkName: "component---src-pages-how-to-players-account-js" */),
  "component---src-pages-how-to-players-faq-js": () => import("./../../../src/pages/how-to/players/FAQ.js" /* webpackChunkName: "component---src-pages-how-to-players-faq-js" */),
  "component---src-pages-how-to-players-index-js": () => import("./../../../src/pages/how-to/players/index.js" /* webpackChunkName: "component---src-pages-how-to-players-index-js" */),
  "component---src-pages-how-to-players-scoring-js": () => import("./../../../src/pages/how-to/players/scoring.js" /* webpackChunkName: "component---src-pages-how-to-players-scoring-js" */),
  "component---src-pages-how-to-players-teams-js": () => import("./../../../src/pages/how-to/players/teams.js" /* webpackChunkName: "component---src-pages-how-to-players-teams-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-test-tourney-js": () => import("./../../../src/pages/testTourney.js" /* webpackChunkName: "component---src-pages-test-tourney-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/TOS.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

